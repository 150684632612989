<template>
<div class="flex ju-between">
   <div style="width:49.5%">
    <MonthlyIndicators></MonthlyIndicators>
   </div>
   <div style="width:49.5%" >
    <CustomerIndicators></CustomerIndicators>
   </div>
</div>
</template>

<script setup>
import { ref } from 'vue'
import MonthlyIndicators from './monthlyIndicators'
import CustomerIndicators from './customerIndicators.vue';

</script>

<style lang="less" scoped>

</style>