<template>
  <div class="monthly">
    <div class="flex al-center ju-between">
      <div class="flex al-center">
        <span class="title">客户指标</span>
        <a class="m-l2" @click="setting">+设置</a>
      </div>
      <div>
        <SearchList @searchAdd="searchAdd" :loading="loading" @resetBtn="resetBtn" :iptData="iptData"></SearchList>
      </div>
    </div>
    <div>
      <a-table :loading="loading" size="small" :columns="columns" bordered :row-key="record => record.id" :pagination="false" :data-source="dataSource">
        <template #add="{ record }">
          <a @click="edit(record)">编辑</a>
          <a-popconfirm title="是否确认删除?" ok-text="确定" cancel-text="取消" @confirm="delBtn(record)">
            <span class="m-l1 cur-p" style="color:#d9001b">删除</span>
          </a-popconfirm>
        </template>
      </a-table>
    </div>
    <a-modal :confirmLoading="loadingBtn" v-model:visible="visible" :title="title" width="25%" @ok="submit" :destroyOnClose="true" @cancel="cancel">
      <div style="margin: 5px 0 5px 0"><span class="reqStyle">*</span>选择月份</div>
      <a-month-picker @change="changeTime" :disabled="editID" :disabled-date="disabledDate" style="width: 100%" v-model:value="targetDate" placeholder="请选择月份" />
      <div class="m-t1">
        <div style="margin: 5px 0 5px 0"><span class="reqStyle">*</span>客户目标金额<span v-if="info.availMonthTargetAmt" style="color:red" class="fz-12">（剩余可分配指标：￥{{ editID ? compute('+', info.availMonthTargetAmt, editNum).result : info.availMonthTargetAmt }}元）</span></div>
        <div v-for="(item,index) in dataList" :key="item.id" class="flex m-b1">
          <a-select :disabled="editID" v-model:value="item.customerId" placeholder="客户名称" style="width: 40%" :filter-option="false" @change="(e1,e2) =>celCustomer(e1,e2,item)" :show-arrow="false" @search="carrierAutoComplete" show-search>
            <template v-if="searchLoading" #notFoundContent>
              <div class=" flex ju-center"><a-spin size="small" /></div>
            </template>
            <a-select-option v-if="!searchLoading" v-for="item in carriers" :key="item.id" :title="item.name">
              <span>{{ item.name }}</span>
              <span>{{ item.mobile }}</span>
            </a-select-option>
          </a-select>
          <div style="width: 10px;"></div>
          <a-input-number  style="width:40%" v-model:value="item.monthTargetAmt" placeholder="输入目标金额"></a-input-number>
          <div v-if="index>0" @click="delAdd(item,index)" class="delBox flex al-center ju-center m-l1 cur-p">
            删除
          </div>
        </div>
        <a @click="addTo" v-if="!editID">添加客户</a>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import SearchList from '@/components/searchList'
import FormList from '@/components/formList'
import { cloneDeep } from 'lodash-es';
import moment from 'moment'
import { transitionTimeYMD, transitionTimeYM } from '@/utils/util'
import { customerPage, customerAdd, monthTargetList, customerEdit, customerDelete } from '@/api/crmManagement/IndicatorConfiguration'
import { message } from 'ant-design-vue';
import { fuzzy } from '@/api/crmManagement/customer'
import { debounce, compute, IfNull } from '@/utils/util'

const loadingBtn = ref(false)
const title = ref('')
const searchMsg = ref({})
const editNum = ref(0)
const editID = ref(null)
const loading = ref(false)
const visible = ref(false)
const dataSource = ref([])
const targetDate = ref(null)
const info = ref({})
const customerId = ref(null)
const searchLoading = ref(false)
const carriers = ref([])
const state = reactive({
  name: ''
})

//删除
const delBtn = (item) => {
  customerDelete({
    id: item.id
  }).then(res => {
    if (res.code !== 10000) return
    message.success('删除成功')
    reqCustomerPage()
  })
}
const submit = () => {
  if (editID.value) {
    let num = dataList.value.map(item => item.monthTargetAmt)
    if (!info.value.availMonthTargetAmt) {
      message.error('没有月份的指标')
      return
    }
    if(compute('+', ...num).result <= 0){
      message.error('目标金额大于0')
      return
    }
    let num2 = compute('+', info.value.availMonthTargetAmt, editNum.value).result
    if (compute('+', ...num).result > num2) {
      message.error('目标金额超出剩余可分配指标')
      return
    }
    loadingBtn.value = true
    customerEdit({
      id: editID.value,
      monthTargetAmt: compute('+', ...num).result
    }).then(res => {
      if (res.code !== 10000) return
      message.success('编辑成功')
      visible.value = false
      reqCustomerPage()
    }).finally(() => {
      loadingBtn.value = false
    })
    return
  }
  
  if (!targetDate.value) {
    message.error('请选择月份')
    return
  }
  let flag1 = dataList.value.every(item => item.customerId)
  if (!flag1) {
    message.error('请选择客户')
    return
  }
  let flag2 = dataList.value.every(item => item.monthTargetAmt)
  if (!flag2) {
    message.error('请输入目标金额且金额不能小于0')
    return
  }
  
  if (!info.value.availMonthTargetAmt) {
    message.error('请先去配置月份的指标')
    return
  }
  let num = dataList.value.map(item => item.monthTargetAmt)
  let flag3 =  num.every(item => item>0)
  if(!flag3){
    message.error('目标金额不能小于0')
    return
  }
  if (compute('+', ...num).result > info.value.availMonthTargetAmt) {
    message.error('目标金额超出剩余可分配指标')
    return
  }
  loadingBtn.value = true
  customerAdd({
    targetDate: transitionTimeYMD(targetDate.value),
    customerTargets: dataList.value,
    monthTargetId: info.value.id
  }).then(res => {
    if (res.code !== 10000) return
    message.success('添加成功')
    reqCustomerPage()
    visible.value = false
  }).finally(() => {
    loadingBtn.value = false
  })
}
//编辑
const edit = (item) => {
  title.value = '编辑'
  editNum.value = item.monthTargetAmt
  editID.value = item.id
  targetDate.value = moment(item.targetDate)
  dataList.value = [{
    customerId: item.customerName,
    monthTargetAmt: item.monthTargetAmt
  }]
  reqMonthTargetList(transitionTimeYMD(item.targetDate),item.salesmanId)
  visible.value = true
}
const carrierAutoComplete = val => {
  if(!IfNull(val)) return
  searchLoading.value = true
  state.name = IfNull(val)
  debounce(state, reqPageList, 1000)
}
const reqPageList = () => {
  fuzzy({
    name: state.name,
    type: 1
  }).then(res => {
    if (res.code === 10000) {
      carriers.value = res.data
    } else {
      carriers.value = []
    }
  }).finally(() => {
    searchLoading.value = false
  })
}
const celCustomer = (e, v, item) => {
  item.customerName = v.title
}

const cancel = () => {
  dataList.value = [cloneDeep(originalData.value)]
}
// 禁用时间
const disabledDate = current => {
  return current && current < moment().subtract(1, 'months')
}
const delAdd = (item, index) => {
  dataList.value.splice(index, 1)
}
const changeTime = (e) => {
  if (!e) return
  reqMonthTargetList(transitionTimeYMD(e))
}

const reqMonthTargetList = (time,salesmanId) => {
  monthTargetList({
    targetDate: time,
    salesmanId:salesmanId
  }).then(res => {
    if (res.code !== 10000) return
    info.value = res.data?.length > 0 ? res.data[0] : {}
  })
}
const searchAdd = (e) => {
  searchMsg.value = cloneDeep(e)
  searchMsg.value.targetDate = transitionTimeYM(e.targetDate) ? transitionTimeYM(e.targetDate) + '-01' : null
  reqCustomerPage()
}
const resetBtn = () => {
  searchMsg.value = {
    targetDate: transitionTimeYMD(new Date().toLocaleDateString()),
  }
  reqCustomerPage()
}

const setting = () => {
  title.value = '添加客户指标'
  editID.value = null
  targetDate.value = null
  dataList.value = [{
    customerId: null,
    monthTargetAmt: null
  }]
  info.value = {}
  visible.value = true
}
const addTo = () => {
  dataList.value.push(cloneDeep(originalData.value))
}
const reqCustomerPage = () => {
  loading.value = true
  customerPage({
    current: 1,
    size: 100,
    ...searchMsg.value
  }).then(res => {
    if (res.code !== 10000) return
    dataSource.value = res.data.records
  }).finally(() => {
    loading.value = false
  })
}
onMounted(() => {
  searchMsg.value = {
    targetDate: transitionTimeYMD(new Date().toLocaleDateString()),
  }
  reqCustomerPage()
})
const originalData = ref(
  {
    customerId: null,
    monthTargetAmt: ''
  }
)
const dataList = ref([
  {
    customerId: null,
    monthTargetAmt: ''
  }
])
const iptData = ref([
  {
    type: "selectTimeYM",
    placeholder: "选择月份",
    value: moment(new Date()) ,
    prop: "targetDate",
    width: 160,
  },
])
const fromList = ref([
  {
    label: "选择月份",
    name: "exceptionType",
    type: "selectTimeYM",
    placeholder: "请选择",
    value: null,
    prop: "exceptionType",
    width: '100%',
    rules: [{ required: true, message: "请选择", trigger: "blur", type: "object" }],
  },
])
const columns = ref([
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
  },
  {
    title: '部门',
    dataIndex: 'orgName',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },

  {
    title: '月份',
    dataIndex: 'targetDate',
    align: 'center',
  },
  {
    title: '客户指标(元)',
    dataIndex: 'monthTargetAmt',
    align: 'center',
  },
  {
    title: '设置时间',
    dataIndex: 'updateTime',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'add',
    slots: {
      customRender: 'add'
    },
    align: 'center',
  },
])
</script>

<style lang="less" scoped>
.monthly {
  padding: 20px;
  background: #fff;
  height: calc(100vh - 120px);
  overflow-y: auto;
}
.title {
  font-size: 18px;
  color: #344563;
  font-weight: 700;
}
.reqStyle {
  color: red;
  font-family: SimSun, sans-serif;
  margin-right: 5px;
}

.delBox {
  height: 30px;
  width: 50px;
  border: 1px solid #d9001b;
  color: #d9001b;
}
</style>