<template>
  <div class="monthly">
    <div class="flex al-center ju-between">
      <div class="flex al-center">
        <span class="title">月总指标</span>
        <a class="m-l2" @click="setting">+设置</a>
      </div>
      <div>
        <SearchList @searchAdd="searchAdd" :loading="loading" @resetBtn="resetBtn" :iptData="iptData"></SearchList>
      </div>
    </div>
    <div>
      <a-table size="small" :columns="columns" bordered :row-key="record => record.id" :loading="loading" :data-source="dataSource" :pagination="false" >
        <template #add="{ record }">
          <a @click="edit(record)">编辑</a>
        </template>
      </a-table>
    </div>
    <a-modal :confirmLoading="loadingBtn" v-model:visible="visible" :title="title" width="25%" @ok="submit" :destroyOnClose="true" @cancel="cancel">
      <FormList @getVal="getVal" ref="formListRef" :disabledDate="disabledDate" :fromList="fromList"></FormList>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { monthTargetAdd, monthTargetPage, monthTargetEdit } from '@/api/crmManagement/IndicatorConfiguration.js'
import SearchList from '@/components/searchList'
import FormList from '@/components/formList'
import moment from 'moment'
import { cloneDeep } from 'lodash-es'
import { transitionTimeYMD,transitionTimeYM } from '@/utils/util'
import { message } from 'ant-design-vue'

const title = ref('')
const editID = ref(null)
const dataSource = ref([])
const loadingBtn = ref(false)
const formListRef = ref(null)
const loading = ref(false)
const visible = ref(false)
const searchMsg = ref({})

const edit = (e) => {
  let str = e.targetDate
  fromList.value[0].value = moment(str)
  fromList.value[1].value = Number(e.monthTargetAmt)
  title.value = '编辑月总指标'
  visible.value = true
  editID.value = e.id
  fromList.value[0].disabled = true
}
const setting = () => {
  fromList.value[0].value = null
  fromList.value[1].value = null
  title.value = '添加月总指标'
  editID.value = null
  visible.value = true
  fromList.value[0].disabled = false
}
// 禁用时间
const disabledDate = current => {
  return current && current < moment().subtract(1, 'months')
}

const searchAdd = (e) => {
  searchMsg.value = cloneDeep(e)
  searchMsg.value.targetDate = transitionTimeYM(e.targetDate) ? transitionTimeYM(e.targetDate) + '-01' : null
  reqMonthTargetPage()
}
const resetBtn = () => {
  searchMsg.value = {
    targetDate:transitionTimeYMD(new Date().toLocaleDateString()),
  }
  reqMonthTargetPage()
}
const getVal = (e) => {
  let msg = cloneDeep(e)
  if (msg.monthTargetAmt <= 0) {
    message.error('月总指标大于0')
    return
  }
  loadingBtn.value = true
  if (!editID.value) {
    msg.targetDate =  transitionTimeYM(e.targetDate) + '-01'
    monthTargetAdd({
      ...msg
    }).then(res => {
      if (res.code !== 10000) return
      message.success('添加成功')
      visible.value = false
      reqMonthTargetPage()
    }).finally(() => {
      loadingBtn.value = false
    })
  }else{
    msg.targetDate =  transitionTimeYMD(e.targetDate)
    monthTargetEdit({
      ...msg,
      id: editID.value
    }).then(res => {
      if (res.code !== 10000) return
      message.success('编辑成功')
      visible.value = false
      reqMonthTargetPage()
    }).finally(() => {
      loadingBtn.value = false
    })
  }


}
const reqMonthTargetPage = () => {
  loading.value = true
  monthTargetPage({
    current: 1,
    size: 100,
    ...searchMsg.value
  }).then(res => {
    if (res.code !== 10000) return
    dataSource.value = res.data.records
  }).finally(() => {
    loading.value = false
  })
}
const submit = () => {
  formListRef.value.getValRef()
}
onMounted(() => {
  searchMsg.value = {
    targetDate:transitionTimeYMD(new Date().toLocaleDateString()),
  }
  reqMonthTargetPage()
})

const iptData = ref([
  {
    type: "selectTimeYM",
    placeholder: "选择月份",
    value:moment(new Date()),
    prop: "targetDate",
    width: 160,
  },
])
const fromList = ref([
  {
    label: "选择月份",
    name: "targetDate",
    type: "selectTimeYM",
    placeholder: "请选择月份",
    value: null,
    prop: "targetDate",
    width: '100%',
    rules: [{ required: true, message: "请选择", trigger: "blur", type: "object" }],
    disabled:false
  },
  {
    label: "月总指标",
    name: "monthTargetAmt",
    type: "inputNum",
    placeholder: "输入目标金额(元)",
    value: null,
    prop: "monthTargetAmt",
    width: '100%',
    rules: [{ required: true, message: "请填写", trigger: "blur", type: "number" }],
  },
])
const columns = ref([
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '部门',
    dataIndex: 'orgName',
    align: 'center',
  },
  {
    title: '月份',
    dataIndex: 'targetDate',
    align: 'center',
  },
  {
    title: '月总指标(元)',
    dataIndex: 'monthTargetAmt',
    align: 'center',
  },
  {
    title: '设置时间',
    dataIndex: 'updateTime',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'add',
    slots: {
      customRender: 'add'
    },
    align: 'center',
  },
])
</script>

<style lang="less" scoped>
.monthly {
  padding: 20px;
  background: #fff;
  height: calc(100vh - 120px);
  overflow-y: auto;
}
.title {
  font-size: 18px;
  color: #344563;
  font-weight: 700;
}
</style>