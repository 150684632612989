import { postBodyRequest, getRequest } from '@/utils/axios'
// 业务员月指标-添加
export function monthTargetAdd (params) {
  return postBodyRequest('/market/month/target/add', params)
}
// 业务员月指标-编辑
export function monthTargetEdit (params) {
  return postBodyRequest('/market/month/target/edit', params)
}
// 业务员月指标-列表
export function monthTargetPage (params) {
  return postBodyRequest('/market/month/target/page', params)
}
export function monthTargetList (params) {
  return postBodyRequest('/market/month/target/list', params)
}
// 客户月指标-分页
export function customerPage (params) {
  return postBodyRequest('/market/month/target/customer/page', params)
}
// 客户月指标-添加
export function customerAdd (params) {
  return postBodyRequest('/market/month/target/customer/add', params)
}
// 客户月指标-编辑
export function customerEdit (params) {
  return postBodyRequest('/market/month/target/customer/edit', params)
}
// 客户月指标-删除
export function customerDelete (params) {
  return getRequest('/market/month/target/customer/delete', params)
}